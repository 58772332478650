import { type ModelsTeamRandomizationSettings } from '@lp-lib/api-service-client/public';

import { type TeamRandomizationSettings } from '../../../../types/game';
import { uncheckedIndexAccess_UNSAFE } from '../../../../utils/uncheckedIndexAccess_UNSAFE';
import { SettingTierData } from './config';
import { type RandomizeConfig } from './types';

export const defaultGamePackRandomizationSettings: TeamRandomizationSettings = {
  t1TeamSize: 1,
  t1TeamSizeMax: 0,
  t2TeamSize: 2,
  t2TeamSizeMax: 3,
  t3TeamSize: 3,
  t3TeamSizeMax: 4,
  t4TeamSize: 3,
  t4TeamSizeMax: 5,
  t5TeamSize: 4,
  t5TeamSizeMax: 5,
  timer: 20,
  oneTeam: false,
  title: null,
  internalLabel: '',
} as const;

export function selectConfig(
  blockFields: TeamRandomizationSettings | ModelsTeamRandomizationSettings,
  totalNumOfPlayer: number
): RandomizeConfig {
  let tierIndex = 0;

  if (totalNumOfPlayer > 0) {
    tierIndex = SettingTierData.findIndex(
      (data) => data.start <= totalNumOfPlayer && totalNumOfPlayer <= data.end
    );

    // Note(falcon): the last setting should have end === Inifinity and should capture
    // a large number of players. as a fallback, we'll use the last defined setting.
    if (tierIndex === -1) {
      tierIndex = SettingTierData.length - 1;
    }
  }

  const teamSizeFieldName = `t${tierIndex + 1}TeamSize`;
  const teamSizeMaxFieldName = `t${tierIndex + 1}TeamSizeMax`;
  const teamSize = uncheckedIndexAccess_UNSAFE(blockFields)[teamSizeFieldName];
  const maxTeamSize =
    uncheckedIndexAccess_UNSAFE(blockFields)[teamSizeMaxFieldName];

  if (teamSize === undefined || maxTeamSize === undefined)
    throw new Error('TeamSize field mismatch');

  return { teamSize, maxTeamSize };
}

export function selectOptionalConfig(
  settings:
    | TeamRandomizationSettings
    | ModelsTeamRandomizationSettings
    | null
    | undefined,
  totalNumOfPlayer: number
): RandomizeConfig {
  return selectConfig(
    settings ?? defaultGamePackRandomizationSettings,
    totalNumOfPlayer
  );
}
